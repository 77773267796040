import { FC } from "react";
import { useFormikContext, Form as FormikForm } from "formik";
import { Box, Button, Grid } from "@mui/material";
import { TextField } from "auditaware-ui";

type NewUserFormProps = {
  onCancelClick: () => void;
};

const NewUserForm: FC<NewUserFormProps> = ({ onCancelClick }) => {
  const { isSubmitting } = useFormikContext();

  return (
    <FormikForm>
      <Grid container spacing={2} sx={{ position: "static", right: "20px" }}>
        <Grid item xs={12} md={12}>
          <TextField name="displayName" id="displayName" label="Display Name" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField name="email" id="email" label="Email Address" fullWidth />
        </Grid>
      </Grid>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "800px",
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          padding: ".5rem",
          zIndex: 1,
        }}
      >
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="success"
          disabled={isSubmitting}
          fullWidth={true}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          disabled={isSubmitting}
          fullWidth={true}
          sx={{ ml: 2 }}
          onClick={onCancelClick}
          type="reset"
        >
          Cancel
        </Button>
      </Box>
    </FormikForm>
  );
};

export default NewUserForm;
