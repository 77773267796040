import { FC, useCallback, useMemo } from "react";
import { Form, Formik } from "formik";
import { Box, Button, Chip, Divider, Grid } from "@mui/material";

import { useAuth } from "@reasongcp/react-fire-sub";
import { TextField, SelectField } from "auditaware-ui";
import { CMRole, CMRoles, DCRole, DCRoles, Role, SSORole } from "auditaware-types";

import useUpdateMember, { MemberUpdates } from "../../../../hooks/useUpdateMember";
import UserFormSchema from "../../../../schemas/UserFormSchema";

interface RoleOption {
  value: string;
  display: React.ReactNode;
}

const ssoRoleOptions: RoleOption[] = [
  { value: SSORole.ADMIN, display: "Admin" },
  { value: SSORole.USER, display: "User" },
];

const caseRoleOptions: RoleOption[] = [
  { value: "", display: <i>No Access</i> },
  { value: CMRole.ADMIN, display: "Admin" },
  { value: CMRole.AGENT_MANAGER, display: "Agent Manager" },
  { value: CMRole.AGENT, display: "Agent" },
  { value: CMRole.AGENT_SUPPORT, display: "Agent Support" },
];

export interface UserFormProps {
  uid: string;
  onCancelClick?: () => void;
  afterSubmit?: () => void;
}

interface MemberInputs extends Omit<MemberUpdates, "roles" | "caseRoles"> {
  role: SSORole;
  caseRole: CMRole;
}

const RoleSelect: FC<{ uid: string }> = ({ uid }) => {
  const user = useAuth();

  if (user?.uid === uid) return null;

  return (
    <>
      <Grid item sm={12} my={1}>
        <Divider>
          <Chip label="Roles" />
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <SelectField name="role" id="role" label="SSO Role" options={ssoRoleOptions} />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          name="caseRole"
          id="caseRole"
          label="Case Manager Role"
          options={caseRoleOptions}
          emptyAsNull
        />
      </Grid>
    </>
  );
};

const EditUserForm: FC<UserFormProps> = ({
  uid,
  onCancelClick,
  afterSubmit = () => {},
}) => {
  const { member, updateMember } = useUpdateMember(uid || "");

  const handleEditUser = useCallback(
    async (input: MemberInputs) => {
      const { role, caseRole, ...rest } = input;

      updateMember({
        ...rest,
        roles: [role, caseRole].filter(Boolean),
      })
        .then(afterSubmit)
        .catch(console.error);
    },
    [updateMember, afterSubmit]
  );

  const initialValues = useMemo(() => {
    const { caseRole, role } = (member?.roles || []).reduce(
      (acc: { caseRole: CMRole; role: SSORole }, role: Role) => {
        switch (true) {
          case CMRoles.includes(role as CMRole):
            return { ...acc, caseRole: role };
          case DCRoles.includes(role as DCRole):
            return { ...acc, canvasRole: role };
          default:
            return { ...acc, role };
        }
      },
      { caseRole: null, role: null, canvasRole: null }
    );

    const state: MemberInputs = {
      displayName: member?.displayName || "",
      email: member?.email || "",
      role,
      caseRole,
    };
    return state;
  }, [member]);

  if (!member) return null;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleEditUser}
      validationSchema={UserFormSchema}
      enableReinitialize
    >
      <Form>
        <Grid container spacing={2} sx={{ position: "static", right: "20px" }}>
          <Grid item xs={12} md={12}>
            <TextField
              name="displayName"
              id="displayName"
              label="Display Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField name="email" id="email" label="Email Address" fullWidth />
          </Grid>
          <RoleSelect uid={uid} />
        </Grid>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
          width: "800px",
            borderTop: "1px solid #e0e0e0",
            display: "flex",
            justifyContent: "space-between",
            padding: ".5rem",
            zIndex: 1,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="success"
            fullWidth={true}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            fullWidth={true}
            sx={{ ml: 2 }}
            onClick={onCancelClick}
            type="reset"
          >
            Cancel
          </Button>
        </Box>
      </Form>
    </Formik>
  );
};

export default EditUserForm;
